/* Supplier List Design */

.suppliercheckbox .ms-Checkbox-checkbox {
  border-radius: 15px !important;
  margin-right: 15px !important;
  width: 16px;
  height: 16px;
 
}
.suppliercheckbox .ms-Checkbox-checkmark{
  font-size: 10px !important;
}
.suppliercheckbox .ms-Checkbox-text {
  font-size: 12px !important;
}
.item2 {
  grid-area: menuList;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.8);
  /* text-align: left; */
  padding: 5px 4px 4px 4px;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 90%;
  /* margin-bottom: 10px; */
  margin: 5px 10px 10px 0px;
}

.item2::-webkit-scrollbar {
  width: 0.6em;
}
.item2::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0.6px solid slategrey;
}

/* Purchase Order Component */
.item3 {
  display: grid;
  grid-area: main;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 5px 0;
  margin: 5px 10px 10px 0px;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow-y: scroll;
}

/* Budget Component Design */
.item5 {
  display: grid;
  grid-area: content3;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 5px 0;
  margin: 5px 10px 10px 0px;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow-y: scroll;
}

/* Grid Structure */
.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 50% 50%;
  grid-template-areas:
    /* 'header header header header header header' */
    'menuList content3 content3 content3 content3 content3 content3'
    /* 'menu main main main right right right'; */
    'menuList main main main main main main';
  grid-gap: 5px;
  background-color: rgb(232, 237, 241, 0.8);
  padding: 5px;
  height: 90.2vh;
  /* position: relative;  */
}

.selectText {
  width: 100%;
  display: inline-block;
  text-align: left;
  font-size: 14px;
  justify-self: start;
  padding-left: 4px;
  padding-bottom: 7px;
  font-weight: 600;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

@media screen and (-ms-high-contrast: black-on-white) {
  .ms-FocusZone div[data-is-focusable="true"]{

    border: 1px white solid;
  }
}

@media screen and (-ms-high-contrast: white-on-black) {
  .ms-FocusZone div[data-is-focusable="true"]{

    border: 1px greenyellow solid;
  }
}

.sr-only{
  position: absolute;
  left: -10000px;
  width:1px;
  height: 1px;
  top: auto;
  overflow: hidden;
}
