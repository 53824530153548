.invoicecheckbox .ms-Checkbox-checkbox {
  border-radius: 15px !important;
  margin-right: 15px !important;
  width: 16px;
  height: 16px;
 
}
.invoicecheckbox .ms-Checkbox-checkmark{
  font-size: 10px !important;
}
.invoicecheckbox .ms-Checkbox-text {
  font-size: 13px !important;
}
.flex-container-invoice-view {
  display: flex;
  flex-direction: column;
  background-color: #faf9f8;
  height: 100%;
}

.invoice-view {
  display: inline-block;
}

.flex-container-invoice-view > div {
  margin: 10px;
}

.flex-container-invoice-view-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
}

.flex-container-invoice-view-1-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
}

.flex-container-invoice-view-1-item > a {
  align-self: flex-start;
  margin-top: 0.6%;
}
.excelIcon {
  height: 70%;
}

.excelIconBillableUnits {
  padding-top: 16px
}
.fontIconExcel {
  font-size: 24px;
  color:rgb(0, 120, 212);
}

.excelIcon > span > i {
  font-size: 24px;
}

.excelIconBillableUnits > span > i {
    font-size: 24px;
}
.flex-container-invoice-view-3 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.flex-container-invoice-view-3-item-1,
.amount-tile-container,
.flex-container-invoice-view-2-item {
  display: flex;
  flex-direction: column;
}

.flex-container-invoice-view-2 {
  display: flex;
  flex-direction: row;
}

.flex-container-invoice-view-2-item-1,
.item-1,
.item-2,
.item-3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item-1 {
  width: 100%;
}

.flex-container-invoice-view-2-item-3 {
  height: 57vh;
  margin-bottom: 2%;
}

@media screen and (max-width: 800px) {
  .flex-container-invoice-view-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .flex-container-invoice-view-1-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .flex-container-invoice-view-3 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  .amount-tile-container {
    display: flex;
    flex-direction: column;
    width: 90% !important;
    flex-wrap: wrap;
  }

  .flex-container-invoice-view-2-item-1,
  .ms-Pivot {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }

  .item-1,
  .item-2 {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    flex-wrap: wrap;
  }

  .item-3 {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
  }

  .ms-ComboBox,
  .ms-Dropdown,
  .ms-ScrollablePane {
    width: 100%;
  }

  .ms-Icon {
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 10%;
  }

  .ms-Button {
    width: 100%;
  }
}
