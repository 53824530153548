.header {
    text-align: left;
    width: 80vw;
    display: block;
    margin: 0 auto;
    height: 6vh;
}

.header-text {
    margin: 1px;
}

.header-actions {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.button-actions {
    display: flex;
    width: 100%;
    height: 15vh;
    justify-content: space-between;
}

.po-modal-container {
    width: 60vw;
    height: 61vh;
    /* margin-bottom: '20px'; */
    /* background-color:#ededed; */

}

.aravo-modal-container {
    width: 23vw;
    height: auto;


}

.aravo-form {
    /* margin: 0 auto; */
    display: block;
    padding-bottom: 5px;
    background-color: #f4f4f4;
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 10px;
    margin: 20px;
    padding: 5px;

}

.po-details {
    width: 56vw;
    margin: 0 auto;
    display: block;
    padding-bottom: 5px;
    background-color: #f4f4f4;
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 10px;
}

span[role="link"] {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  span[role="link"]:focus {
    outline: 1px dotted black;
  }