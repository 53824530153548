 .invoiceView,.SDMView
{
    height: 100%;
    width: 80%;    
} 

.mainview
{
    height: 100%;
    width: 30%;
    padding-right: 200px;
    position: sticky;     
    overflow-y: hidden;
}
h4
{
    font-size: 18px;
}

.content
{
    text-align: justify;
    width: 85vw;
    height:91vh;
    padding-right: 150px;
    padding-left: 30px;    
    font-size: 14px;
    overflow-x: hidden;    
}
.helpmain
{
    height: 91%;
}