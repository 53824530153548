.contractInfo {
  margin: auto;
  padding: 0px 20px 10px 20px;
  /* height: 100%; */
  /* TEMP fix for height: */
  height: 43vh;
  max-height: 43vh;
  display: flex;
  flex-direction: column;
  /* top right bottom left */
}
.ms-DetailsRow-cell{
  white-space: initial !important;
}

.bottomNav {
  width: 100%;
}

.myLink {
  float: left;
}

.buttonGroup {
  float: right;
}

.contractInfoGrid {
  display: grid;
  /* margin: 2%; */
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  font-size: 16px;
  grid-template-columns: 150px 200px 120px auto;
  padding: 0px 20px 10px 0px;
}
.contractInfoGridAccessible {
  display: grid;
  /* margin: 2%; */
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  font-size: 16px;
  padding: 0px 20px 10px 0px;
}

.searchBox {
  z-index: 1;
  grid-column-start: 1;
  grid-column-end: 3;
}

.searchResults {
  grid-row-start: 1;
  height: 60vh;
  /* overflow: auto; */
  z-index: 0;
  position: relative;
  /* height: 35vh; */}

.descr,
.adds,
.amends {
  grid-column-start: 4;
  grid-column-end: 7;
}

.createShellLink {
  font-style: italic;
}

.descrLabel,
.addsLabel,
.amendsLabel,
.idLabel,
.typeLabel,
.expDateLabel,
.supplierNameLabel {
  font-weight: bold;
}

.idLabel,
.typeLabel,
.expDateLabel,
.supplierNameLabel {
  grid-column-start: 1;
}

.isSharedContractCheckBox .ms-Checkbox-label {
  font-weight: bold;
}
