.contractShellModalContainer {
  padding: 10px 20px 20px 20px;
  overflow-x: hidden;
}

div.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.description {
  /* padding: 0px 0px 40px 0px; */
  height: 70px;
  margin: 5px 0px;
}

.supplierName,
.companyCode {
  margin: 5px 0px;
  /* max-height: 50px; */
  /* overflow: auto; */
}
#supName-label 
{
  color: #000000 !important;
}
.ms-Dialog-main{ 
  overflow-y: hidden !important;
}
