.graphHeader {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.flex-container-supplier-budget {
  display: flex;
  flex-direction: column;
  font-size: 1.17em;
}

.flex-container-supplier-budget > div {
  margin: 8px;
}

.flex-container-supplier-budget-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
}

.flex-container-supplier-budget-1-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-container-supplier-budget-3 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 99%;
}

.flex-container-supplier-budget-2 {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

.flex-container-supplier-budget-budget-chart {
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-size: small;
  font-weight: bold;
}
