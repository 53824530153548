.unAuthUser_Main_Container {
  text-align: center;
  background-color: rgb(232, 237, 241, 0.8);
  height: 100vh;
}

.unAuth_Message_Container {
  margin-top: 5px;
  text-align: left;
  padding: 5px;
  font-size: 14px;
  background: #fff;
  padding-left: 15px;
}

.unAuth_Form_Container {
  width: 58%;
  margin-left: 15px;
  height: 80vh;
  max-height: 80vh;
  text-align: left;
  padding: 5px;
  /* Next 3 lines will give me the "card feel */
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.roleConainer {
  padding: 10px !important;
  height: 35vh;
  max-height: 35vh;
  overflow-y: scroll;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.roleConainer::after {
  content: '*';
  color: rgb(164, 38, 44);
  position: absolute;
  top: -5px;
  right: -3px;
}

.unauth_Request_Form > .ms-Grid-row {
  padding: 10px !important;
}

.unauth_Request_Form > .inputCol {
  padding: 0px !important;
}

@media only screen and (max-width: 768px) {
  .unAuth_Form_Container {
    width: 91.1% !important;
    margin-right: 20px;
  }
}
