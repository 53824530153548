/* Grid Structure */
.report-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
    'reportHeader reportHeader'
    'reportTabs reportTabs'
    'reportFilters reportFilters'
    'reportContent1 reportContent1'
    'reportContent2 reportContent3';
    grid-template-rows:5% 9% 13% 38% 35%; 
    background: #fff;
    height: 89vh;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  @media screen and (max-width: 800px){
    .report-container {
      height: 100% !important;
    }
  }
  @media screen and (max-width: 768px){
    .report-container {
      height: 89vh !important;
    }
  }

  .report-header{
    display: grid;
    grid-area: reportHeader;
    padding: 5px 0;
    font-size: 14px;
    border-radius: 2px;    
  }

  .report-tabs{
    display: grid;
    grid-area: reportTabs;
    padding: 5px 0;
    font-size: 14px;
    border-radius: 2px;    
  }

  .report-filters{
    display: grid;
    grid-area: reportFilters;
    padding: 5px 0;
    font-size: 14px;
    border-radius: 2px;    
    align-items: end;
  }

  .report-content1{
    display: grid;
    grid-area: reportContent1;
    padding: 5px 0;
    font-size: 14px;
    border-radius: 2px;    
  }

  .report-Content2{
    margin-top: 2vh;
    display: grid;
    grid-area: reportContent2;
    padding: 5px 0;
    font-size: 14px;
    border-radius: 2px; 
  }

  .report-content3{
    display: grid;
    grid-area: reportContent3;
    padding: 5px 0;
    font-size: 14px;
    border-radius: 2px; 
  }

  .bobReportDetailList .ms-DetailsHeader{
    padding-top: 0px !important;
    padding-bottom: 5px;
    min-height: 20px !important;
    max-height: 25px;
  }

  .reportFilter label{
    font-size: 12px;
  }

  .bobReportDetailList .ms-DetailsRow-cell{
    padding: 0px 0px 0px 3px;
    font-size: 10px;
    font-weight: 400;
    min-height: 20px !important;
    max-height: 25px;
  }

  .bobReportDetailList .ms-List-cell{
    min-height: 20px !important;
    max-height: 25px;
  }

  .stickyFooter .ms-DetailsRow-cell{
    padding: 0px !important;
  }

  .reportTotalLabel{
    font-size: 17px !important;
    display: inline;
    padding-right: 10px;
    font-weight: 400;
  }

  .exportDetailsBtn .ms-Spinner-circle{
    border-style: dashed;
    border-width: 3px;
    animation-duration: 2.0s;
  }
  @media screen and (max-width: 800px){
    .report-tabs .flex-container-invoice-view-2-item-1, .ms-Pivot {
  flex-direction: row !important;
}
}

@media screen and (min-width:800px) and (max-width :900px){
  .report-content1 {
    padding: 3% 0 !important;

}
  .report-content3 {
    padding: 7% 0 !important;
}
}
@media screen and (min-width:700px) and (max-width :800px){
  .report-container {
    height: 100% !important;
    grid-template-rows: 6% 9% 15% 54% 35% !important;
      }
    .tableViewInvoice{
      height: 50vh !important;
    }
    .budgettable{
      height: 50vh !important;
    }
}
@media screen and (min-width :600px) and (max-width :700px){
  .report-container {
    height: 100% !important;
    grid-template-rows:5% 9% 15% 38% 35% !important;
  }
  .invoicelabel{
    margin-top: 0% !important;
  }
  .report-filters{
    padding: 15px 0 !important;
  }
  .report-content1 {
  padding: 18% 0 !important;
  }
  .tableViewInvoice{
    height: 50vh !important;
  }
  .budgettable{
    height: 50vh !important;
  }
  .report-content3 {
    margin-top: 10vh !important;
  }

}
@media screen and (min-width :500px) and (max-width :600px){

  .report-container {
    height: 100% !important;
    grid-template-rows: 5% 9% 36% 35% 35% !important;
  }
  .invoicelabel{
    margin-top: 10% !important;
  }
  .report-filters{
    padding: 15px 0 !important;
  }
  .tableViewInvoice{
    height: 50vh !important;
  }
  .budgettable{
    height: 50vh !important;
  }
    .report-content1 {
      padding: 5% 0 !important;
    }
}
@media screen and (min-width :400px) and (max-width :500px){
  .report-container {
    height: 100% !important;
    }
  .report-filters{
    padding: 15px 0 !important;
  }
  .invoicelabel{
    margin-top: 10% !important;
  }
  .report-container {
    height: 100% !important;
    grid-template-rows:5% 9% 40% 28% 35% !important;
  }
  .tableViewInvoice{
    height: 50vh !important;
  }
  .budgettable{
    height: 50vh !important;
  }
  .label-121 {
    font-size: 60% !important;
  }
}
@media screen and (min-width :300px) and (max-width :400px){

  .report-container {
    height: 100% !important;
    grid-template-rows:5% 9% 45% 26% 35% !important;
    }
  .report-filters{
    padding: 25px 0 !important;
  }
  .invoicelabel{
    margin-top: 5% !important;
  }
  .tableViewInvoice{
    height: 60vh !important;
  }
  .budgettable{
    height: 60vh !important;
  }
  .label-121 {
    font-size: 60% !important;
    }
}

.root-133 .ms-DetailsHeader-cellName{
    word-break: break-word !important;
  }

 
  /* @media screen and (-ms-high-contrast: black-on-white) { 
    
    .report-filters.ms-Checkbox.ms-Dropdown-item input[type="checkbox"]:focus{
  
      border: 1px red solid;
      background: blueviolet;
    }
    .report-filters.ms-Checkbox.ms-Dropdown-item input[type="checkbox"]:hover{
  
      border: 1px red solid;
      background: blueviolet;
    }
  }
  
  
  @media screen and (-ms-high-contrast: white-on-black) { 
    .report-filters.ms-Checkbox.ms-Dropdown-item input[type="checkbox"]:focus{
  
      border: 1px greenyellow solid;
      background: blueviolet;

    }
    .report-filters.ms-Checkbox.ms-Dropdown-item input[type="checkbox"]:hover{
  
      border: 1px red solid;
      background: blueviolet;
    }
  
  } */
  

