.priorPeriodAdjustmentModalContainer {
  padding: 5px 20px 10px 10px;
}

div.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
.headcountcheckbox .ms-Checkbox-checkbox {
  border-radius: 15px !important;
  margin-right: 15px !important;
  width: 16px;
  height: 16px;
 
}
.headcountcheckbox .ms-Checkbox-checkmark{
  font-size: 10px !important;
}
.headcountcheckbox .ms-Checkbox-text {
  font-size: 13px !important;
}

.textField {
  height: 70px;
  margin: 10px 0px;
  width: 250px;
}

.priorPeriodButtonGroup {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.invoicePeriodLabel {
  font-size: 14px;
  font-weight: 600;
}

.invoiceDisplayPeriodLabel {
  font-size: 14px;
  font-weight: 400;
}

.detailsListStyle {
  width: 0vw;
}

.acceptRejectIcon > span > i {
  font-size: 20px;
}
.StateStyles {
  font-size: 1px;
  color: white;
}

.nrcModal {
  padding: 5px 20px 10px 20px;
}
.nrcModalProxy {
  padding: 5px 20px 10px 20px;
  height: 51vh;
}

.addIcon > span > i {
  font-size: 12px;
}

.addNrcModal {
  height: 78vh;
  width: 25vw;
  padding-left: 1vw; 
}

.addProxyModal {
  height: 50vh;
  width: 25vw;
  padding-left: 1vw;
}

.addNrcButtonGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 5vh;
  margin-bottom: 10vh;
}

.nrcDropdowns {
  width: 90%;
}

@media screen and (-ms-high-contrast: black-on-white) {
  .nrcDropdowns > .ms-Dropdown-label {
    margin-bottom: 3px;
    margin-top: 5px;
    color:black !important;
  }
}
@media screen and (-ms-high-contrast: white-on-black) {
  .nrcDropdowns > .ms-Dropdown-label {
    margin-bottom: 3px;
    margin-top: 5px;
    color:white !important;
  }
  .nrcDropdowns > .ms-Dropdown-label::after {
    margin-bottom: 3px;
    margin-top: 5px;
    color:white !important;
  }
}   
.nrcDropdowns > .ms-Dropdown-label {
  margin-bottom: 3px;
  margin-top: 5px;
  color: black;
}

.nrcProxyDropdowns {
  width: 90%;
}

.nrcProxyDropdowns > .ms-Dropdown-label {
  margin-bottom: 3px;
  margin-top: 5px;
  color: black;
  width:110%;
}

.nrcTextBoxes {
  margin-bottom: 2vh;
  width: 85%;
}

.ms-TextField-errorMessage {
  position: fixed;
}

.saveButton .is-disabled {
  background-color: transparent;
}

.nrcTextBoxes .ms-TextField-suffix {
  width: 16%;
}

.flex-container-headcount {
  flex-direction: column;
  background-color: #faf9f8;
  height: 100%;
}

.flex-container-headcount > div {
  margin: 5px;
}

.flex-container-headcount-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
}

.flex-container-headcount-1-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}

.flex-container-headcount-1-item-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 39%;
}

/* .submittedAutomatedSEIDDiv .ms-Toggle.is-enabled.root-106{
  float: left !important;
} */

.flex-container-headcount-1-item-1 > a {
  margin-top: 8%;
  align-self: flex-start;
}


.filter-dropdowns .ms-Dropdown-container {
  width: 40%;	
}

.flex-container-headcount-3-item-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  float: right;
}

.flex-container-headcount-3-item-1 > div {
  display: flex;
  flex-direction: row;
}

.flex-container-headcount-4 {
  display: flex;
  flex-direction: row;
}

.flex-container-headcount-4-item {
  width: 100%;
  height: 70vh;
  margin-bottom: 0.5%;
}

.flex-container-headcount-5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-container-headcount-5-item-1 {
  display: flex;
  flex-direction: column;
  width: 13%;
}

.flex-container-headcount-5-item-2 {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  float: right;
  margin-right: 1%;
}

.flex-container-headcount-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 2%;
}

.filter-display :hover {
  background-color: transparent;
}

.filter-display > span > button > span > i {
  font-size: 13px;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 10px;
}

.submittedAutomatedSEIDDiv {
    margin-top: 9px;
}


.submittedAutomatedSEIDLabel {
    font-size: 14px;
    margin: 10px 4px 0px 0px
}
.submittedAutomatedSEIDDiv1 {
 width:54%
}
.parentheadcount {
  display: grid;
}
.word_div{
  word-break: normal;
}
/* .list_highlight{
  border: 1px solid rgb(32, 31, 30);
} */
