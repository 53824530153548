.po-review-container {
  padding: 1vw 1vw 1vw 1vw;
  background-color: rgb(232, 237, 241, 0.8);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 40% 60%;
  grid-gap: 5px 5px 5px 5px;
  height: 100%;
  overflow: hidden;
  grid-template-areas:
    'container1 container2'
    'container3 container3';
}
.budgetchart-container_1 {
  padding: 1vw 1vw 2vw 1vw;
  display: grid;
  grid-template-rows: 100%;
  overflow: hidden;
  grid-template-areas:
    'container1 container2'
    'container3 container3';
}
.submitbutton {
  background-color: rgb(232, 237, 241, 0.8);
  margin-left: 1vw;
  padding-right: 15px;
  padding-bottom: 5px;
}
