.supplier-engagement-container {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin-top: 4% !important;
  padding: 5px 0;
  margin: 15px 0px 15px 0px;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.engagement-details-header {
  font-weight: bold;
  font-size: 1.17em;
  padding: 8px;
  width: 16.5vw;
  margin: 0;
}
.supplier-engagement-container  .cell-115 {
  white-space:normal !important;
}
