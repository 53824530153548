.supplier-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1%;
  margin: 0% 1% 1% 0%;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  height: 100%;
}
.wordwraptitle{
  word-break: break-word;
}

.supplier-details-1 {
  font-weight: bold;
  justify-self: start;
  align-self: center;
  font-size: 1.17em;
  padding-left: 8px;
  margin: 0px;
}

.supplier-details-3 {
  border: 1px;
  border-style: hidden;
  font-weight: 400;
  justify-self: center;
  font-size: 1.17em;
  padding: 8px;
}

.supplier-details-3 > h5 {
  padding: 2px;
  margin: 0px 0px 2px 0px;
  font-size: 14px;
}

.supplier-details-6 {
  border: 1px;
  border-style: hidden;
  justify-self: center;
  align-self: center;
  font-size: 1.17em;
  padding-left: 8px;
}

.supplier-details-6 > span {
  font-weight: 300;
  padding: 2px;
  font-size: 14px;
  font-weight: 400;
}

.supplier-details-6 > h5 {
  padding: 2px;
  margin: 0px 0px 2px 0px;
  font-size: 14px;
}

.textFieldStyle {
  font-weight: bold;
  text-align: center;
}