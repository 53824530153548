.existingContractsList {
  margin: auto;
  overflow-y: auto;
  padding: 0px 20px 10px 20px;
  display: flex;
  flex-direction: column;
}

.listItems {
  overflow: auto;
  margin: 0px 0px 10px 0px;
  position: relative;
}
