.selectedListDiv{
    height: 30vh;
    overflow: auto;
    margin-top: 2vh;
}
.grid-list {
    display: grid;
    grid-template-columns: repeat(auto, 1fr);
  }
  .selectedListDiv-length{
    height: 8vh;
    overflow: auto;
    margin-top: 2vh;
}
.grid-list-length {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

.idListBorder {
    border:1px solid black;
    margin: auto;
}
.mainDiv {
    height:100%
}
.pageTitle{
    padding: 10px;
    font-size: 18.72px;
}
.filterRow{
    margin-left: 10px;
}
.searchButton{
    margin-top:4vh;
    margin-left:4vh;
}
.editButton{
margin-left: 10px;
margin-top: 15px;
}
.commonMargin{
    margin: 10px;
}
.editButtonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 5vh;
    margin-bottom: 10vh;
  }
  .gridproperties{
    height: 68vh;
    position: relative;
    margin-left: 1vw;
  }
  
