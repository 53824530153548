.mainLayoutGrid {
  text-align: center;
  padding: 1% 1%;
  background-color: #faf9f8;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.contractInformation {
  /* height: 46vh;
  max-height: 42vh; */
  text-align: left;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 14px;
  /* Next 3 lines will give me the "card feel */
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(148, 126, 126, 0.23);
}
/* .resizecontractinformationwidth640{
  height: 80vh !important;
}
.resizecontractInfo640{
  height: 79vh !important;
    max-height: 80vh !important;
}
.resizecontractinformationwidth426{
  height: 100vh !important;
}
.resizecontractInfo426{
  height: 98vh !important;
    max-height: 99vh !important;
}
.listItemsheight320{
  height: 60vh !important;
} */

.existingContracts {
  /* height: 43vh;
  max-height: 40.8vh; */
  text-align: left;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 14px;
  /* Next 3 lines will give me the "card feel */
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}