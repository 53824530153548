.mapContractContainer {
  padding: 5px 20px 10px 20px;
  width: 750px;
}

.mapButtonGroup {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.shellsList {
  max-height: 350px;
  overflow: auto;
}
