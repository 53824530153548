.contract-container {
  background-color: rgba(255, 255, 255, 0.8);
    padding: 1%;
    margin: 0% 0% 1% 1%;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
  height: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 50%;
}

.contract-container-nocard {
  padding: 1%;
  margin: 0% 0% 1% 1%;
  font-size: 14px;
  height: 100%;
  grid-area: container2;
}

.contract-details-header {
  font-weight: bold;
  font-size: 1.17em;
  padding: 8px;
  width: 10vw;
  margin: 0;
}
