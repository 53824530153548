.testRoot {
  height: auto;
  overflow-y: auto;
  max-height: inherit;
  position: absolute;
  width: 360px;
  background-color: white;
  z-index: 1;

  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
