
/* Remove default bullets */
ul, #myUL {
    list-style-type: none;
  }
  li{
      font-size: 13px;
  }
  
  /* Remove margins and padding from the parent ul */
  #myUL {
    margin: 0;
    padding: 0;
  }
  
  /* Style the caret/arrow */
  .caret1 {
    cursor: pointer;
    user-select: none; /* Prevent text selection */
  }
  
  /* Create the caret/arrow with a unicode, and style it */
  .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }
  
  /* Rotate the caret/arrow icon when clicked on (using JavaScript) */
  .caret-down::before {
    transform: rotate(90deg);
  }
  
  /* Hide the nested list */
  .hide {
    display: none;
  }
  
  /* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
  .show {
    display: block;
  }
  .ulinnerclass{
    font-size:15px;
    margin-top:5px;
  }
  .liinnerclass {
      margin-left: 5px;
      margin-top: 5px;
  }
  .list1 .liinnerclass .hide{
      display: none;
  }
  .list1 .liinnerclass .show{
    display: block;
}
.list2 .liinnerclass .hide{
    display: none;
}
.list2 .liinnerclass .show{
  display: block;
}
.list3 .liinnerclass .hide{
    display: none;
}
.list3 .liinnerclass .show{
  display: block;
}